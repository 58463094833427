import React from "react";

import Header from "./components/header";
import Footer from "./components/footer";

const Econom = () => {
  return (
    <div>
      <Header />
      <main className="bg-gray-200 py-8 space-y-8">
        <div className="mx-auto max-w-6xl container p-8 bg-white space-y-4">
          <h2>Обеспечение экономической безопасности</h2>
          <div className="news-text">
            <p>
              Практика показывает, что значительная часть бизнесменов начинает
              задумываться об обеспечении безопасности только тогда, когда
              наступают негативные последствия: хищение, предательство интересов
              компании, конфликтная ситуация с партнерами или государственными
              органами. Но даже в этих случаях усилия, как правило, направляются
              не на обеспечение безопасности в целом, а разрешение конкретной
              ситуации, и сводятся к поиску знакомых в правоохранительной сфере
              или человека, способного в силу своего авторитета выступить
              третейским судьей в конфликтной ситуации.{" "}
            </p>
            <p>
              Наше консалтинговое агентство предлагает решение вопросов,
              связанных с обеспечением безопасности, как на разовой, так и
              постоянной основе. Мы предлагаем следующие услуги:
            </p>
            <h3>Проверка контрагентов</h3>
            <p>
              На сегодняшний день в большинстве компаний стандартная проверка
              потенциальных контрагентов сведена к получению информации об
              юридических лицах из открытых информационных ресурсов. В
              исключительных случаях сотрудники служб безопасности проводят
              проверки физических лиц по криминальным учетам с помощью связей в
              правоохранительных органах.
            </p>
            <p>
              Подход к решению данного вопроса в агентстве «КФП-Консалтинг»
              значительно шире. Наши сотрудники из числа бывших оперативников
              системы МВД, имеющие статус частных детективов, соберут
              максимально возможную информацию о контрагенте гласным и негласным
              путем, при необходимости выедут в любую точку страны или
              организуют наблюдение за объектом.
            </p>
            <p>
              Вся собранная информация на условиях конфиденциальности будет
              передана заказчику, который сам будет определять пределы ее
              использования.
            </p>
          </div>
          <h3>Проверка персонала</h3>
          <p>
            Мероприятия, направленные на повышение качества работы персонала,
            проводятся как на этапе его подбора, так и с уже действующими
            сотрудниками. При этом решаются следующие задачи:
          </p>
          <p>
            <ul className="list-disc list-inside px-6">
              <li>
                недопущение фактов принятия на работу людей с асоциальным
                поведением, склонных к совершению противоправных поступков или
                предательству интересов компании;
              </li>
              <li>выявление таких людей среди уже работающих сотрудников;</li>
              <li>оптимизация организационно-штатного построения;</li>
              <li>
                изучение морально-психологического климата в коллективе и его
                корректировка
              </li>
            </ul>
          </p>
          <p>
            Качественным отличием работы агентства «КФП-Консалтинг» является то,
            что нашими сотрудниками проводится не формальная проверка кандидата
            или сотрудника по криминальным и иным учетам, а целый комплекс
            мероприятий, позволяющих сформировать максимально объективное мнение
            о человеке.
          </p>
          <p>
            С этой целью проводится сбор сведений по месту жительства, прежней
            работы, а также в среде ближайшего окружения кандидата или
            сотрудника.
          </p>
          <p>
            Полученные данные изучаются, обобщаются и совместно со
            специалистами-полиграфологами разрабатываются индивидуальные тесты,
            позволяющие с высокой долей вероятности получить правдивую
            информацию об образе жизни изучаемого лица.
          </p>
          <p>
            Полиграфологические исследования персонала могут проводиться и по
            стандартным тестам, что оправдано в отношении сотрудников, не
            имеющих контрольно-распорядительные функции или допуск к
            расходованию или распределению денежных и материальных средств.
          </p>
          <p>
            Изучение морально-психологического климата проводится
            профессиональными психологами на основе самых современных методик.
            Регулярное проведение данных мероприятий позволяет не только
            своевременно принимать меры по ликвидации проблем, влияющих на
            работоспособность коллектива, но и определять рейтинги руководителей
            разного уровня, а также выявлять неформальных лидеров, способных
            консолидировать усилия коллектива на решении поставленных задач.
          </p>
          <h3>
            Выявление внешних и внутренних угроз безопасности и их нейтрализация
          </h3>
          <p>
            Мероприятия по данному направлению планируются и осуществляются в
            соответствии со спецификой экономической деятельности заказчика и
            имеют своей целью не только выявление, но и, прежде всего,
            профилактику возможного нанесения экономического ущерба предприятию
            в результате противоправного поведения отдельных сотрудников или
            иных причин.
          </p>
          <p>
            Весь объем мероприятий по данному направлению разрабатывается
            совместно с заказчиком, при этом определяется перечень необходимой
            документации, порядок доступа к ней, а также меры по сохранению
            конфиденциальности полученных сведений.
          </p>
          <p>
            Все мероприятия, связанные с получением гласной информации от
            контрагентов, проводятся только после согласования этого вопроса
            между заказчиком и его партнерами.
          </p>
          <p>
            По результатам проведенной работы заказчику предоставляется весь
            объем полученной информации и рекомендации по повышению качества
            экономической безопасности, а при его желании сотрудники агентства
            буду заниматься реализацией разработанных мер на постоянной основе.
          </p>
          <h3>
            Изучение состояния технической и антитеррористической защищенности
            объектов.
          </h3>
          <p>
            В ходе работы по этому направлению осуществляются следующие
            мероприятия:
          </p>
          <p>
            <ul className="list-disc list-inside px-6">
              <li>изучение имеющейся документации по данному направлению;</li>
              <li>изучение организации работы сторожевой охраны;</li>
              <li>
                изучение организации видеонаблюдения и работы иных технических
                средств безопасности;
              </li>
              <li>изучение технической укрепленности помещений.</li>
            </ul>
          </p>
          <p>
            По результатам проведения данного комплекса составляется комплект
            необходимой документации, регламентирующий правила внутреннего
            распорядка и пропускного контроля, а для объектов с массовым
            пребыванием людей – изготавливается или корректируется паспорт
            безопасности; разрабатываются схемы организации видеонаблюдения и
            сторожевой охраны, даются рекомендации по ее работе, при
            необходимости подбирается частное охранное предприятие, подходящее
            под требования заказчика; выполняются работы по установлению систем
            видеонаблюдения и других технических средств безопасности.
          </p>
          <h3>Комплексный аудит безопасности</h3>
          <p>
            Аудит безопасности заключается в проведении комплекса мероприятий,
            позволяющих сделать оценку защищенности проверяемого объекта в самых
            различных сферах, в зависимости от специфики его деятельности;
            подготовить предложения по ее совершенствованию и оказать помощь в
            реализации данных рекомендаций.
          </p>
          <p>
            Перед началом аудита составляется в план, включающий в себя
            исчерпывающий перечень проводимых мероприятий, который в
            обязательном порядке согласовывается с заказчиком. При его желании в
            него могут быть дополнительно включены специфические мероприятия,
            такие как исследования качества строительных и ремонтных работ,
            финансовый аудит, мероприятия по экологической безопасности и т.п.
          </p>
          <p>
            Все полученные сведения являются строго конфиденциальными и
            доводятся только до заказчика. Лишь заказчик определяет порядок их
            использования, кроме случаев, прямо указанных в законодательстве.
            Агентство несет ответственность за сохранность полученных
            конфиденциальных данных.{" "}
          </p>
          <p>
            По итогам аудита готовится акт, в котором отражается состояние
            безопасности на предприятии и содержатся рекомендации по повышению
            ее уровня.
          </p>
          <p>
            При желании заказчика специалисты «КФП-Консалтинг» будут
            осуществлять консультирование или исполнение рекомендованных
            мероприятий.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Econom;
